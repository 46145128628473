// This file should include some common knowledge data like list of countries, languages etc.

// TODO: list country names per code
export const countryCodes = [
    'WO', 'EP', 'EA',
    'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 
    'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 
    'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 
    'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 
    'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 
    'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 
    'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 
    'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 
    'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 
    'JE', 'JM', 'JO', 'JP', 
    'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 
    'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 
    'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 
    'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 
    'OM', 
    'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 
    'QA', 
    'RE', 'RO', 'RS', 'RU', 'RW', 
    'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 
    'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 
    'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 
    'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 
    'WF', 'WS', 
    'YE', 'YT', 
    'ZA', 'ZM', 'ZW'
]
// we are dropping IRAN
export const brandCountries = ['IR', 'EU', ...countryCodes.slice(3).filter(c => c !== 'IR')]

export const familyMemberStates = ['in-preparation', 'pending', 'granted', 'stopped']
export const ipTypes = ['patent', 'utility-model']

export const family_member = "family-member"
export const patent_family = "patent-family"
export const orignation_link = "origination-link"
export const agent_link = "agent-link"
export const image_link = "image-link"
export const commodity = 'commodity'
export const commodity_claim_scope_link = "commodity-claim-scope-link"
export const commodity_family_link = "commodity-family-link"
export const commodity_country_link = "commodity-country-link"
export const invention = 'invention'
export const invention_inventor_link = "invention-inventor-link"
export const invention_country_link = "invention-country-link"
export const evaluation_link = "evaluation"
export const evaluation_meta_link = "evaluation-meta"
export const trademark_family = "trademark-family"
export const trade_mark = "trademark"
export const trademark_class = "trademark-class"
export const trademark_product_link = "trademark-commodity-link"
export const trademark_agent_link = "trademark-agent-link"

export const tags_flag = "tags"
export const claim_scope_flag = "claim-scopes"
export const product_country_flag = "product-country"
export const annuities_flag = "annuities"
export const annuities_admin_flag = "annuity-admin"
export const excel_import_flag = "excel-import"
export const excel_export_flag = "excel-export"
export const tasks_flag = "tasks"
export const costs_flag = "costs"
export const invention_flag = "invention"
export const new_innovation_flag = "new-innovation"
export const portfolio_update_flag = "portfolio-update"
export const brand_flag = "brands"
export const documents_flag = "documents"
export const new_design_flag = "new-design"
export const tracking_flag = "tracking"
export const prio_sort_flag = "prio-sort"

export const euCountries = [
    "AT", "BE", "BG", "CY", "CZ", 
    "DE", "DK", "EE", "ES", "FI", "FR", 
    "GR", "HR", "HU", "IE", "IT", 
    "LT", "LU", "LV", "MT", "NL", 
    "PL", "PT", "RO", "SE", "SI", "SK"
]

const notParticipating = new Set(["ES", "HR"])
const notRatified = new Set(["CY", "CZ", "GR", "HR", "HU", "IE", "PL", "RO", "SK"])

export const epCountries = [
    "AL", "AT", "BE", "BG",  "CH", "CY", "CZ", 
    "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR","HR", "HU", "IE", "IS", "IT",
    "KH", "LI", "LV", "LT", "LU", "MA", "MC", "MD", "MK", "MT", "NL", "NO",
    "PL", "PT", "RS", "RO", "SE", "SI", "SK", "SM", "TN", "TR",
]

export const unitaryPatentCountries = euCountries
    .filter(c => !notParticipating.has(c))
    .filter(c => !notRatified.has(c))

export const eaCountries = [
    "AM", "AZ", "BY", "KG", "KZ", "RU", "TJ", "TM",
]

export const supportedCurrencies = [
    "EUR", "CHF", "USD", "GBP"
]

export const plans = [
    'demo', 'free', 'basic', 'plus', 'premium',
]

export const display_names = {
    'score': 'Score',
    'none': 'None',
    'dueDate': 'Due Date',
    'fee': 'Fee',
    'payment': 'Payment',
    'person': 'Person',
    'company': 'Company',
    'not-managed': 'Not Managed', 
    'managed': 'Managed', 
    'automatic': 'Automatic', 
    'manual': 'Manual',
    'view-role': 'View Role',
    'user-role': 'User Role',
    'admin-role': 'Admin Role',
    'super-admin-role': 'Super Admin Role',
    'title': "Title",
    'familyName': "Family Name",
    'country': "Country",
    'countries': "Countries",
    'summary': "Summary",
    'keyWords': "Key Words",
    'internalReference': "Reference",
    'externalReference': "External Reference",
    'familyStatus': "Family Status",
    'countryCode': "Country",
    'countryCodes': "Countries",
    'familyMemberStatus': 'Patent Status',
    'active': 'Active',
    'ipType': 'IP Type',
    'applicationNumber': 'Application Number',
    'applicationDate': 'Application Date',
    'publicationNumber': 'Publication Number',
    'publicationDate': 'Publication Date',
    'patentNumber': 'Patent Number',
    'patentDate': 'Patent Date',
    'patentOfficeLink': 'Patent Office Link',
    'applicationLanguage': 'Application Language',
    'validated': 'Validation',
    'pctRouteFiling': 'PCT Route Filing',
    'firstFiling': 'First Filing',
    'patent': 'Patent',
    'utility-model': 'Utility Model',
    'in-preparation': 'In Preparation',
    'pending': 'Pending',
    'granted': 'Granted',
    'stopped': 'Stopped',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'name': 'Name',
    'inventor': 'Inventor',
    'owner': 'Owner',
    'applicant': 'Applicant',
    'inventors': 'Inventors',
    'owners': 'Owners',
    'applicants': 'Applicants',
    'claimNumber': 'Claim Number',
    'claimType': 'Claim Type',
    'claimScope': 'Claim Scope',
    'version': 'Version',
    'versionDate': 'Version Date',
    'claimText': 'Claim Text',
    'main-claim': 'Main Claim',
    'independent-claim': 'Independent Claim',
    'dependent-claim': 'Dependent Claim',
    'commodityReference': 'Product',
    'commodityName': 'Product Name',
    'commodityClass': 'Product Class',
    'commodityDescription': 'Product Description',
    'status': 'Status',
    'planned': 'Planned',
    'tags': 'Tags',
    'priorityDate': 'Priority Date',
    'expiryDate': 'Expiry Date',
    'reason': 'Comments',
    'comments': 'Comments',
    'image': 'Image',
    'numberClaims': 'Number of Claims',
    'technical': 'Technical',
    'commercial': 'Commercial',
    'legal': 'Legal',
    'financial': 'Financial',
    [family_member]: "Family Member",
    [patent_family]: "Patent Family",
}

export function show(str: string) {
    return display_names[str] ?? str
}


export function extractContent(s: string) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };


// NOTES: if a field is not an Option in Scala, it has to be required here
export const personFields = [
    { name: 'firstName', type: 'text', required: true },
    { name: 'lastName', type: 'text', required: true},
    { name: 'agentType', type: 'const', value: 'person' },
]

export const formikPersonFields = ({agentId}) => [
    ...personFields, 
    { name: 'agentId', type: 'const', value: agentId}
]

export function personValidation(otherPersons = []) {
    return {
        lastName: (ln, person) => {
            const existing = otherPersons
                .find(p => p.firstName.toLowerCase().trim() === person.firstName.toLowerCase().trim()
                    && p.lastName.toLowerCase().trim() === ln.toLowerCase().trim()
                    && p.agentId !== person.agentId)
            //console.log(existing, person, otherPersons)
            return existing
                ? "Person already exists"
                : undefined
        }
    }
}

export const companyFields = [
    { name: 'name', type: 'text', required: true },
    { name: 'agentType', type: 'const', value: 'company' },
]

export const formikCompanyFields = ({agentId}) => [
    ...companyFields,
    { name: 'agentId', type: 'const', value: agentId }
]

export function companyValidation(otherCompanies = []) {
    return {
        name: (n) => otherCompanies.find(c => c.name.toLowerCase() === n.toLowerCase()) ? "Company already exists" : undefined
    }
}

export const userRoles = [
    //"view-role",
    "user-role",
    "admin-role",
]

// If this gets cumbersome: https://tailwindcss.com/docs/configuration#referencing-in-java-script
export const colors = {
    'pcx-100': 'hsl(213, 100%, 98%)', //'#f4f9ff',
    'pcx-200': 'hsl(211, 95%, 95%)', //'#e9f3fe',
    'pcx-300': 'hsl(209, 85%, 72%)', //'#7bbaf4',
    'pcx-400': 'hsl(207, 75%, 59%)', //'#489ee5',
    'pcx-500': 'hsl(205, 69%, 45%)', //'#247fc1',
    'pcx-600': 'hsl(208, 75%, 35%)', //'#175f9e',
    'pcx-700': 'hsl(209, 82%, 30%)', //'#0e4f8b',
    'pcx-800': 'hsl(211, 89%, 25%)', //'#073f7a',
    'pcx-900': 'hsl(212, 96%, 18%)', //'#022a58',
}

export const scoreColors = {
    'no-score': 'rgb(254 242 242)', // 'rgb(239 68 68)', // 'rgb(220 38 38)',
    'low-score': 'rgb(254, 202, 202)', 
    'medium-score': 'rgba(254, 240, 138, 0.8)', 
    'high-score': 'rgb(217 249 157)', 
    'very-high-score': 'rgba(22, 163, 74, 0.5)',
}
