import { IconChevronDown } from "../../components/icons"
import { createContext, Fragment, useContext, useState } from "react"
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from "react-i18next"
import _ from "lodash"
import clsx from "clsx"

import { useAuth, useRoles } from "../../user/Auth"
import { useLocalState } from "../../settings/localStorage"
import { useBackend } from "../../BackendProvider"
import { byFamilyMember, useRowsProvider } from "./Rows"
import Modal from "../../components/Modal"
import { Field, Form, Formik } from "formik"

interface QueryAction {
    value: string
    label: string
    disabled?: boolean
}

const QueriesContext = createContext({
    queryActions: [] as QueryAction[],
    queryAction: "" as string,
    selectedQueryId: -1, 
    setSelectedQueryId: (id: number) => {},
    selectedQuery: undefined as Query | undefined,
    setQueryModal: (modal: QueryModal | undefined) => {},
    showSaveAsModal: false as Boolean,
    showOpenModal: false as Boolean,
    showDeleteModal: false as Boolean,
    selectQuery: (action: string | number) => {},
})

type QueryModal = "save-as" | "open" | "delete"

export function QueriesProvider({children}) {
    //console.log('recalculating QP')
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const { dataQueries } = useBackend()
    const { searchFields, setSearchFields, setGroupBy } = useRowsProvider()

    const [selectedQueryId, setSelectedQueryId] = useLocalState('analytics-query-id', -1)
    const [queryAction, setQueryAction] = useState("")

    const [queryModal, setQueryModal] = useState(undefined as QueryModal | undefined)
    const showSaveAsModal = queryModal === "save-as"
    const showOpenModal = queryModal === "open"
    const showDeleteModal = queryModal === "delete"

    const dataQueryById = _.keyBy(dataQueries, q => q.queryId)

    const selectedQuery = dataQueryById[selectedQueryId]

    const queryActions:  QueryAction[] = [
        {disabled: false, value: "_new", label: t('new')},
        {disabled: dataQueries.length === 0, value: "_open", label: t('open')},
        {disabled: !isEditUser || selectedQuery === undefined, value: "_save", label: t('save')},
        {disabled: !isEditUser || searchFields.length === 0, value: "_save_as", label: t('save-as')},
        {disabled: !isEditUser || selectedQuery === undefined, value: "_rename", label: t('rename')},
        {disabled: !isEditUser || selectedQuery === undefined, value: "_delete", label: t('delete') + " " + (selectedQuery?.queryName ?? '')},
    ]

    function selectQuery(action: string | number) {
        //const action = event?.target?.value
        switch(action) {
            case "_new":
                setSearchFields([])
                setGroupBy(byFamilyMember)
                setSelectedQueryId(undefined)
                break
            case "_open": 
                setQueryModal("open")
                break
            case "_save": 
            case "_save_as": 
            case "_rename": 
                setQueryAction(action)
                setQueryModal("save-as")
                break
            case "_delete": 
                setQueryModal("delete")
                break
            case "": break
            default:
                const queryId = action as number
                const query = dataQueryById[queryId]
                setSelectedQueryId(queryId)
                //console.log(query)
                setGroupBy(query.groupBy)
                setSearchFields(query.fields)
        }
    }

    const value = {
        queryAction, queryActions,
        setQueryModal,
        selectQuery,
        selectedQueryId, setSelectedQueryId, selectedQuery,
        showOpenModal, showSaveAsModal, showDeleteModal,
    }

    return <QueriesContext.Provider {...{value}}>
        {children}
    </QueriesContext.Provider>
}

export function useQueriesProvider() {
    return useContext(QueriesContext)
}

export function QueryMenu() { //{selectQuery, queryActions}: {selectQuery: (query: string) => void, queryActions: QueryAction[]}) {
    const {t} = useTranslation()
    const {selectQuery, queryActions} = useQueriesProvider()
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="btn-secondary rounded-sm inline-flex justify-center font-normal focus:outline-0 focus:ring-0">
                    {t('query-actions')}
                    <IconChevronDown aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-sm bg-white border border-pcx-500 shadow-lg ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none overflow-hidden">
                    {queryActions.map(({ value, label, disabled }) =>
                            <Menu.Item disabled={disabled} key={label}>
                                {({ active }) => 
                                    <button 
                                        onClick={() => selectQuery(value)} 
                                        className={clsx(
                                            disabled ? 'text-gray-400' 
                                            : active ? 'bg-pcx-200 text-pcx-800' : 'text-pcx-700', 
                                            'w-full text-left block px-4 py-2 text-sm')}
                                    >{label}</button>}
                            </Menu.Item>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export function QueryOpenModal() {
    const { t } = useTranslation()

    const {dataQueries} = useBackend()
    const {setSelectedQueryId, setQueryModal} = useQueriesProvider()
    const {setSearchFields, setGroupBy} = useRowsProvider()

    const doSelect = ({queryId, groupBy, fields}) => {
        setSelectedQueryId(queryId)
        setGroupBy(groupBy)
        setSearchFields(fields)
        setQueryModal(undefined)
    }

    return (
        <Modal escAction={() => setQueryModal(undefined)}>
            <div className="dark:bg-pcx-100">
                <h4 className="px-5 pt-4">{t('open-query')}</h4>
                <div className="p-4">
                    <div className="border border-pcx-300 rounded-md p-1 flex flex-col gap-1">
                        {_(dataQueries)
                            .sortBy(q => q.queryName)
                            .map(query =>
                                <button
                                    key={query.queryId}
                                    className="text-left border border-pcx-200 rounded-md px-2 py-px hover:bg-pcx-100 dark:hover:bg-pcx-200 sm:min-w-xs"
                                    onClick={() => doSelect(query)}>
                                    {query.queryName}
                                </button>)
                            .value()}
                    </div>
                </div>
                <div className="px-5 py-4 bg-pcx-200 flex flex-row-reverse gap-2">
                    <button onClick={() => setQueryModal(undefined)} className="btn-secondary">{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

export function QueryDeleteModal() {
    const { t } = useTranslation()

    const {entityOperation, dataQueries} = useBackend()
    const {selectedQueryId, setQueryModal, setSelectedQueryId} = useQueriesProvider()

    const query = dataQueries.find(q => q.queryId === +selectedQueryId)
    const doDelete = () => entityOperation('data-query', 'delete', +selectedQueryId)
        .then(() => {
            setSelectedQueryId(undefined)
            setQueryModal(undefined)
        })

    return (
        <Modal escAction={() => setQueryModal(undefined)}>
            <div>
                <h4 className="p-4">{t('delete-query-question', {queryName: query?.queryName})}</h4>
                <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                    <button onClick={() => doDelete()} className="btn-warn">{t('delete')}</button>
                    <button onClick={() => setQueryModal(undefined)} className="btn-secondary">{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

export interface Query {
    queryId?: number
    queryName: string
    username: string
    groupBy: string
    fields: string[]
}

export interface QueryInput {
    queryName?: string
}

export function QueryEditorModal() {
    const { t } = useTranslation()
    const {user: {name: username}} = useAuth()
    const {entityOperation, dataQueries} = useBackend()

    const {groupBy, searchFields: fields} = useRowsProvider()
    const {setQueryModal, selectedQueryId, setSelectedQueryId, queryAction} = useQueriesProvider()

    //const selectedQueryId = +_selectedQueryId
    const selectedQuery = dataQueries.find(q => q.queryId === selectedQueryId)

    const ops = (queryAction === "_save_as" || selectedQuery === undefined) ? "add" : "update"
    const isSave = queryAction === "_save" && selectedQuery?.queryName
    const isRename = queryAction === "_rename"

    const otherQueries = new Set(dataQueries.map(q => q.queryName))

    function operation(queryName) {
        const dataQuery = { queryName, username, groupBy, fields, queryId: selectedQueryId }
        return entityOperation('data-query', ops, dataQuery)
            .then((resp: Query) => resp?.queryId && setSelectedQueryId(resp?.queryId))
            .finally(() => setQueryModal(undefined))
    }

    return (
        <Modal escAction={() => setQueryModal(undefined)}>
            {isSave
                ? <div>
                    <h3 className="p-4">{t('save-query-question')}</h3>
                    <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                        <button className="btn-primary" onClick={() => operation(selectedQuery.queryName)}>{t('save')}</button>
                        <button className="btn-secondary" onClick={() => setQueryModal(undefined)}>{t('cancel')}</button>
                    </div>
                </div>
                : <Formik
                    initialValues={{ queryName: selectedQuery?.queryName ?? '' } as QueryInput}
                    onSubmit={({ queryName }) => operation(queryName)}
                    validate={({ queryName }) => {
                        const errors = {} as QueryInput
                        if (queryName === "")
                            errors.queryName = t('empty-query-name-error')
                        else if (queryName.startsWith("_"))
                            errors.queryName = t('underscore-query-error')
                        else if (otherQueries.has(queryName))
                            errors.queryName = t('query-name-used-error')
                        return errors
                    }}
                >{({ errors, values }) =>
                    <Form>
                        <div className="p-4">
                            <h2 className="pb-2">{
                                ops === "add" 
                                    ? t('save-as-new-query')
                                    : isRename
                                    ? t("rename-query", { queryName: selectedQuery.queryName })
                                    : t("save-query", { queryName: selectedQuery.queryName })
                            }
                            </h2>
                            <label>
                                <div className="pb-1 text-slate-700">{t('query-name')}</div>
                                <Field autoFocus className="form-input w-full" name="queryName" required />
                                {errors.queryName && <div className="text-red-700 pt-2">{errors.queryName}</div>}
                            </label>
                        </div>
                        <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                            <input
                                disabled={isRename && values.queryName === selectedQuery?.queryName}
                                className="btn-primary disabled:btn-disabled"
                                type="submit" value={t('save')}
                            />
                            <button className="btn-secondary" onClick={() => setQueryModal(undefined)}>{t('cancel')}</button>
                        </div>
                    </Form>
                }</Formik>
            }
        </Modal>
    )
}