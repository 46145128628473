import {CalendarDate, startOfMonth, parseDate } from '@internationalized/date';

export type InstructionTimelineSettings = {
    renewalNoticeMonths: number;
    instructionPeriodMonths: number;
    paymentTermsDays: number;
}

/**
 * 
 * N: Renewal Notice Period in Months
 * I: Instruction Period in Months
 * P: Payment Terms in Days
 * M: Manual Instruction Period = I - P - 1M
 * T: Fee Due Date
 * open period months = N - I
 * 
 * N - I -P is by default 5-3-30
 * 
 * Time Line
 *   > early
 * T - N months -x days (for start of month): renewal notice is being sent; now client can instruct
 *   > open
 * T - I months -x days - y buffer days
 *   > critical
 * T - I months -x days (for start of month): instruction due date last day for possible instruction; now client needs to instruct DM by Email
 *   > manual-instruction
 * T - P days - 1 month -x days (for start of month): invoice is being sent
 *   > too-late
 * 
 */
export type DueDateStatus = "early" | "open" | "critical" | "manual-instruction" | "too-late"

export function calculateTimeline(dueDate: string, today: CalendarDate, settings: InstructionTimelineSettings): {status: DueDateStatus, instructionDueDate: string} {
    if (dueDate === undefined)
        return {status: "early", instructionDueDate: undefined}

    //const tz = getLocalTimeZone()
    //const today = createToday(tz)
    const patentDueDate = parseDate(dueDate)
    
    const _instructionDueDate = startOfMonth(patentDueDate.subtract({months: settings.instructionPeriodMonths}))
    const instructionDueDate = _instructionDueDate.toString()

    if (today.compare(_instructionDueDate) < 0) {
        // we before the instruction date
        const openPeriodMonths = settings.renewalNoticeMonths - settings.instructionPeriodMonths 
        const openDate = _instructionDueDate.subtract({ months: openPeriodMonths })
        if (today.compare(openDate) < 0) {
            // we are early
            return { status: "early", instructionDueDate }
        } else {
            const criticalDate = _instructionDueDate.subtract({ days: 3 })
            if (today.compare(criticalDate) < 0) {
                // we are open
                return { status: "open", instructionDueDate }
            } else {
                // we are critical
                return { status: "critical", instructionDueDate }
            }
        }
    } else {
        const invoiceDate = _instructionDueDate.add({ months: 1, days: settings.paymentTermsDays })
        if (today.compare(invoiceDate) < 0) {
            // we are manual-instruction
            return { status: "manual-instruction", instructionDueDate }
        } else {
            return { status: "too-late", instructionDueDate }
        }
    }
}

/** 
 * Calculate the first possible fee due date based on a instruction due date
 * 
 * Instruction Due Date = Start Of Month (Patent Due Date - Instruction Period)
 *  => First possible fee due date = StartOfMonth(Instruction Due Date + Instruction Period)
*/ 
export function calculateFirstPossibleDueDate(instructionDueDate: CalendarDate, settings: InstructionTimelineSettings): CalendarDate {
    return startOfMonth(instructionDueDate.add({ months: settings.instructionPeriodMonths }))
}

/**
 * Calculate the latest possible fee due date based on a instruction due date
 * 
 * Instruction Due Date = Start Of Month (Patent Due Date - Instruction Period)
 *  => Latest possible fee due date = EndOfMonth(Instruction Due Date + Instruction Period)
 */
export function calculateLatestPossibleDueDate(instructionDueDate: CalendarDate, settings: InstructionTimelineSettings): CalendarDate {
    // go to the first of the next month and then back one day
    return new CalendarDate(instructionDueDate.year, instructionDueDate.month, 1)
        .add({months: settings.instructionPeriodMonths + 1})
        .subtract({ days: 1 })
}