import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    useReactTable,
    ColumnDef,
    CellContext,
    Header,
    SortingState,
    Table,
  } from "@tanstack/react-table";
import { Link, useSearchParams } from "react-router-dom";
import { ArrowPathRoundedSquareIcon, CheckCircleIcon, StopIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import _ from "lodash";

import { Member } from "../patents/patents"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { PcIpRight, ValidationMessage, useDennemeyer, useIpRightsPortfolio, useSynchronize } from "./DennemeyerProvider"
import { memberUrl } from "../patents/utils";
import { capitalize } from "../utils/strings"
import { IconArrowUpDown, IconCheck, IconChevronDown, IconChevronUp, IconEdit, IconSpinner, IconX } from "../components/icons";
import { useRoles } from "../user/Auth";
import { RenewalState, deriveInitialIpRight, extractMissingValue, extractState, renewalStateOrder } from "./utils"
import { PauseCircleIcon, PlayCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { ImportState, TransferStatus, useImportState } from "./DennemeyerImportProvider";

type IpRightRow = (Member & {
    ipRight?: PcIpRight & {ownerId?: number}, 
    validations: ValidationMessage[],
    renewalStatus: RenewalState,
    ipImport?: ImportState,
    nextRenewalDueDate?: string,
    errors: string[],
})

declare module '@tanstack/react-table' {
    // @ts-ignore
    interface TableMeta {
        editedRows: Record<number, IpRightRow>,
        setEditedRows: Dispatch<SetStateAction<Record<number, IpRightRow>>>,
        //isEditing: boolean,
        //setIsEditing: Dispatch<SetStateAction<boolean>>,
        getEditState: (rowId: number | string, path: string, table: Table<IpRightRow>) => [boolean, any, IpRightRow, (value: any) => void],
        //getEditedRowValue: (rowId: number | string, path: string) => [IpRightRow, any],
        //setEditedRowValue: (rowId: number | string, path: string, value: any) => void,
        saveRow: (rowId: number | string) => Promise<IpRightRow>,
        saveEditedRows: () => Promise<[PcIpRight]>,
    }
}

const columnHelper = createColumnHelper<IpRightRow>()

function HeaderCell(props: { header: { id: string; }; }) {
    const {t} = useTranslation()

    return (
        <span className="text-left text-pcx-600 text-xs whitespace-nowrap font-semibold uppercase tracking-wider">
            {t(props.header.id)}
        </span>
    )
}

function ReferenceCell({getValue}) {
    const internalReference = getValue()
    return (
        <Link to={memberUrl({ internalReference })} className="text-pcx-600 hover:text-pcx-400 whitespace-nowrap">
            {internalReference}
        </Link>
    )
}

/*
function Cell({getValue, row}) {
    let value = getValue()
    if (value === false)
        value = 'no'
    else if (value === true)
        value = 'yes'
    //console.log({row})
    return (
        <span>
            {value ?? null}
        </span>
    )
}*/

const colors = {
    "ignored": "bg-slate-200 text-slate-500",
    "pct-not-importable": "bg-slate-200 text-slate-500",

    "not-yet-ready": "bg-red-100 text-red-600",
    "validation-errors": "bg-amber-100 text-amber-600",

    "not-sent": "bg-pcx-200 text-pcx-600",

    "sent": "bg-pcx-200 text-pcx-600",
    "ready-at-payment-provider": "bg-green-100 text-green-600",
}

function RenewalStateCell({getValue, row}: CellContext<IpRightRow, RenewalState>) {
    const {t} = useTranslation()
    const state = getValue()

    const isDirty = row.original.ipRight?.dirty

    const messages = 
        state === 'validation-errors' ? row.original.validations.map(m => <li key={m.validationId} title={m.message}>{t(`dm_messages.${m.errorCode}`)}</li>) :
        state === 'not-yet-ready' ? row.original.errors.map((e, i) => <li key={i}>{t(e)}</li>) :
        []

    const status = (state === 'sent' || state === 'ready-at-payment-provider') ? 'handled' : 'not-handled'

    return <div className="relative group w-fit">
        {t(status)}
        {isDirty && <div className="absolute -right-2 bottom-0 h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />}
        <div className={clsx("hidden group-hover:block absolute p-2 z-10 mt-2 ml-2 rounded shadow border border-current", colors[state])}>
            <h2 className="text-sm font-medium mb-2 whitespace-nowrap">{t('renewals-desc.' + state)}</h2>
            {messages.length > 0 && <>
                <h3 className="text-sm font-medium text-current mb-1">
                    {state === 'not-yet-ready' ? t('errors-missing') : t('dm_validation_errors')}
                </h3>
                <ul className="text-sm">
                    {messages}
                </ul>
            </>}
        </div>
    </div>
}

/*
function RenewalHeaderCell({header}: {header: {id: string}}) {
    const {t} = useTranslation()

    return (
        <span title={t(header.id)} className="inline w-fit h-fit p-0.5 rounded-sm xborder border-pcx-600 text-pcx-600">
            <IconCircleQuestion className="h-5 w-5 inline" />
        </span>
    )
}*/

const oldPaymentProviderStates = [
    { state: 'active', icon: <PlayCircleIcon className="h-5 w-5" /> },
    { state: 'inactive', icon: <PauseCircleIcon className="h-5 w-5 opacity-60" /> },
    { state: 'unknown', icon: <QuestionMarkCircleIcon className="h-5 w-5" /> },
]


function OldPaymentProviderCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>) {
    const {t} = useTranslation()
    const value = getValue()
    const meta = table.options?.meta

    const path = 'ipImport.statusOld'
    const [isEditing, editedValue,, updateRowValue] = meta?.getEditState(row.id, path, table)

    //console.log({value})
    if (isEditing) {
    //console.log({editedRow})
        return (
            <select value={editedValue} className={smallFormSelect} onChange={e => updateRowValue(e.target.value)}>
                {oldPaymentProviderStates.map(state =>
                    <option key={state.state} value={state.state}>
                        {t(state.state)}
                    </option>
                )}
            </select>
        )
    } else if (value) {
        return <span className="pr-3 whitespace-nowrap">{t(value) ?? ''}</span>
    } else {
        return null
    }
}

const smallFormInput = "form-input text-sm py-0 pl-2 pr-7 truncate -ml-2"
const smallFormSelect = "form-select text-sm py-0 pl-2 pr-7 truncate -ml-2"

/*
function IpSubTypeCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>)  {
    const value = getValue()
    const meta = table.options?.meta

    const [isEditing, editedValue, editedRow, updateRowValue] = meta?.getEditState(row.id, 'ipRight.ipSubType', table)

    if (isEditing) {
        //console.log({editedRow})
        const ipSubTypes = editedRow.ipRight.ipType === 'patent' ? patentIpSubTypes : utilityModelIpSubTypes
        return <select
            className={clsx(smallFormSelect, 'w-32 truncate pr-7')} value={editedValue}
            onChange={e => updateRowValue(e.target.value)}
        >
            {ipSubTypes.map(type => <option key={type} value={type}>{capitalize(type)}</option>)}
        </select>

    } else if (value) {
        return <span className="pr-3 whitespace-nowrap">{value ?? ''}</span>
    } else {
        return null
    }
}

function OriginCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>) {
    const value = getValue()
    const meta = table.options?.meta

    const [isEditing, editedValue, editedRow, updateRowValue] = meta?.getEditState(row.id, 'ipRight.origin', table)

    if (isEditing) {
        //console.log({editedRow})
        return <select
            className={clsx(smallFormSelect, 'w-24 truncate pr-7')} value={editedValue}
            onChange={e => updateRowValue(e.target.value)}
        >
            {origins.map(orig => <option key={orig} value={orig}>{orig}</option>)}
        </select>

    } else if (value) {
        return <span className="pr-3 whitespace-nowrap">{value ?? ''}</span>
    } else {
        return null
    }
}

function StatusCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>) {
    const value = getValue()
    const meta = table.options?.meta

    const [isEditing, editedValue, editedRow, updateRowValue] = meta?.getEditState(row.id, 'ipRight.status', table)

    if (isEditing) {
        return <select 
            className={smallFormSelect}
            value={editedValue}
            onChange={e => updateRowValue(e.target.value) }>
                {statuses.map(stat => <option key={stat} value={stat}>{stat}</option>)}
        </select>
    }
    else if (value) 
        return <span className="pr-3 whitespace-nowrap">{value ?? ''}</span>
    return null
}


function OwnerCell({getValue, row, table}: CellContext<IpRightRow, number | undefined>) {
    const {ownerById, owners} = useDennemeyer()
    const value = getValue()
    const meta = table.options?.meta

    const [isEditing, editedValue, editedRow, updateRowValue] = meta?.getEditState(row.id, 'ipRight.ownerId', table)

    if (editedValue) {
        return <select 
            className={smallFormSelect}
            value={editedValue}
            onChange={e => updateRowValue(parseInt(e.target.value)) }>
            {_(owners
                .map(owner => 
                    <option key={owner.ownerId} value={owner.ownerId}>
                        {owner.name}
                    </option>))
                .value()}
        </select>
    }
    else if (value) 
        return <span className="pr-3 whitespace-nowrap">{ownerById[value]?.name ?? ''}</span>
    return null
}

type PcLinkedCostCenter = PcCostCenter & PcCostCenterLink

function CostCenterCell({getValue, row, table}: CellContext<IpRightRow, PcLinkedCostCenter[] | undefined>) {
    const _value = getValue() ?? []
    const meta = table.options?.meta

    const ipRightId = row.original.ipRight?.ipRightId

    const [isEditing, editedValue, editedRow, updateRowValue] = meta?.getEditState(row.id, 'ipRight.costCenters', table)

    function onChange(e: { target: { value: PcLinkedCostCenter[] } }) {
        updateRowValue(e.target.value)
    }
    const value = editedValue ?? _value

    return <CostCenterSelection
        className="w-40"
        {...{ ipRightId, value, onChange, disabled: editedValue === undefined }}
    />
}*/

function NextDueDateCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>) {

    const value = getValue()
     if (value) 
        return <span className="pr-3 whitespace-nowrap">{value ?? ''}</span>
    return null
}

function CommentCell({getValue, row, table}: CellContext<IpRightRow, string | undefined>) {
    const ref = useRef<HTMLInputElement>()
    const value = getValue()
    //console.log({value})
    const meta = table.options?.meta

    //const editedRow = meta?.editedRows[row.id]
    const path = 'ipImport.comment'
    const [isEditing, editedValue,, updateRowValue] = meta?.getEditState(row.id, path, table)

    if (isEditing) {
        return <input type="text" 
            className={clsx(smallFormInput, 'w-48 xl:w-64')}
            ref={ref}
            defaultValue={editedValue}
            onBlur={() => updateRowValue(ref.current.value)} // Only change on blur to avoid too many updates/rerenders
        />
    } else if (value) 
        return <div className="-ml-2 pl-2 pr-3 whitespace-nowrap w-48 xl:w-64 truncate" title={value ?? ''}>{value ?? ''}</div>
    return null
}

const transferStates = [
    { state: 'complete', icon: <CheckCircleIcon className="h-5 w-5" /> },
    { state: 'incomplete', icon: <StopIcon className="h-5 w-5" /> },
    { state: 'unknown', icon: <QuestionMarkCircleIcon className="h-5 w-5" /> },
]

function TransferCell({getValue, row, table}: CellContext<IpRightRow, TransferStatus>) {
    const {t} = useTranslation()

    const value = getValue()
    const meta = table.options?.meta

    const path = 'ipImport.transferState'
    const [isEditing, editedValue,, updateRowValue] = meta?.getEditState(row.id, path, table)

    //return <span className="capitalize text-pcx-600 font-medium">{t(status)}</span>
    if (isEditing) {
    //console.log({editedRow})
        return (
            <select value={editedValue} className={smallFormSelect} onChange={e => updateRowValue(e.target.value)}>
                {transferStates.map(state =>
                    <option key={state.state} value={state.state}>
                        {capitalize(t(state.state))}
                    </option>
                )}
            </select>
        )
    } else if (value) {
        return <span className="pr-3 whitespace-nowrap">{capitalize(t(value))}</span>
    } else {
        return null
    }
}

/*
function EditHeader({header, table}: HeaderContext<IpRightRow, unknown>) {
    const {t} = useTranslation()
    const {triggerSynchronize, synchronizing} = useSynchronize()

    const meta = table.options?.meta

    function startEditing() {
        meta?.setIsEditing(true)
    }

    function cancelAction() {
        meta?.setIsEditing(false)
        meta.setEditedRows({})
    }

    async function saveRows() {
        const ipRights = await meta?.saveEditedRows()
        triggerSynchronize(ipRights.filter(r => r.dirty).map(r => r.ipRightId))
        cancelAction()
    }

    const isEditing = meta?.isEditing
    //console.log({isEditing, meta})

    return (
        <div className="flex flex-row gap-1 justify-end w-12">
            {isEditing 
                ? <>
                    <button
                        className="btn-neutral p-px text-sm" title={t('cancel')}
                        onClick={cancelAction}
                    >
                        <IconX />
                    </button>
                    <button
                        className="btn-primary p-px text-sm" title={t('save')}
                        onClick={saveRows}
                    >
                        {synchronizing ? <IconSpinner className="h-5 w-5 animate-spin" /> :  <IconCheck className="w-5 h-5" />}
                    </button>
                </>
                : <button className="btn-secondary p-px" title={t('edit')} onClick={startEditing}>
                    <IconEdit className="h-5 w-5" />
                </button>}
        </div>
    )
}*/

function EditCell({ row, table }: CellContext<IpRightRow, unknown>) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const {owners} = useDennemeyer()
    const {triggerSynchronize, synchronizing} = useSynchronize()
    const meta = table.options.meta 

    //console.log('edit cll')

    if (!isEditUser) return null

    const fallbackOwner = _.sortBy(owners, 'name')[0]

    const isDirty = row.original.ipRight?.dirty === true

    return (
        <div className="flex flex-row justify-end gap-1 w-12">
            {meta?.editedRows[row.id]
                ? <>
                    <button 
                        className="btn-neutral p-px text-sm" title={t('cancel')} 
                        onClick={() => meta?.setEditedRows(rows => ({ ...rows, [row.id]: undefined }))}
                    >
                        <IconX />
                    </button>
                    <button 
                        className="btn-primary p-px text-sm" title={t('save')}
                        onClick={async () => {
                            const r = await meta?.saveRow(row.id)
                            if (r?.ipRight?.dirty === true)  {
                                triggerSynchronize([r.ipRight?.ipRightId])
                            }
                        }}
                    >
                        <IconCheck className="w-5 h-5" />
                    </button>
                </>
                : <> 
                    {isDirty &&
                        <button className="btn-primary p-px" title={t('renewals-desc.send')}
                            onClick={() => triggerSynchronize([row.original.ipRight?.ipRightId])}
                        >
                            {synchronizing 
                                ? <IconSpinner className="h-5 w-5 animate-spin" />
                                : <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                            }
                        </button>}
                    <button
                        className="btn-secondary p-px text-sm" title={t('edit')}
                        onClick={() => meta?.setEditedRows(rows => {
                            const original = { ...row.original }
                            if (original?.ipRight?.ipRightId === undefined) {
                                original.ipRight = deriveInitialIpRight(original)
                                original.ipRight.ownerId = fallbackOwner?.ownerId 
                            }
                            if (original.ipImport === undefined) {
                                original.ipImport = {
                                    ipRightId: original.ipRight.ipRightId,
                                    statusOld: "unknown",
                                    transferState: "unknown",
                                }
                            }
                            return { ...rows, [row.id]: original }
                        })}
                    >
                        <IconEdit className="w-5 h-5" />
                    </button>
                </>}
        </div>
    )
}

function createColums() {
    return [
        columnHelper.accessor('internalReference', {
            header: HeaderCell,
            cell: ReferenceCell,
        }),
        columnHelper.accessor(row => row.renewalStatus, {
            id: 'newPaymentProvider',
            //header: RenewalHeaderCell,
            header: HeaderCell,
            cell: RenewalStateCell,
            sortingFn: (row1, row2, id) => {
                const d1 = row1.original?.ipRight?.dirty === true
                const d2 = row2.original?.ipRight?.dirty === true
                const o1 = renewalStateOrder[row1.getValue<string>(id)] + d1
                const o2 = renewalStateOrder[row2.getValue<string>(id)] + d2
                if (o1 === o2) return 0
                else if (o1 < o2) return -1
                else return 1
            }
        }),
        columnHelper.accessor(row => row.ipImport?.statusOld, {
            id: 'oldPaymentProvider',
            header: HeaderCell,
            cell: OldPaymentProviderCell,
        }),
        /*
        columnHelper.accessor(row => capitalize(row.ipRight?.ipSubType), {
            id: 'ipSubType',
            cell: IpSubTypeCell,
            header: HeaderCell,
        }),
        columnHelper.accessor(row => (row.ipRight?.origin), {
            id: 'origin',
            cell: OriginCell,
            header: HeaderCell,
        }),
        columnHelper.accessor(row => (row.ipRight?.status), {
            id: 'status',
            cell: StatusCell,
            header: HeaderCell,
        }),
        columnHelper.accessor(row => row.ipRight?.ownerId, {
            id: 'owner',
            cell: OwnerCell,
            header: HeaderCell,
        }),
        columnHelper.accessor(row => row.ipRight?.costCenters, {
            id: 'cost-centers',
            cell: CostCenterCell,
            header: HeaderCell,
        }),*/
        //columnHelper.accessor(row => row.ipRight?.firstDueDate, {
        columnHelper.accessor(row => row.nextRenewalDueDate, {
            id: 'nextDueDate',
            cell: NextDueDateCell,
            header: HeaderCell,
        }),
        columnHelper.accessor(row => row.ipImport?.comment, {
            id: 'comment',
            cell: CommentCell,
            header: HeaderCell,
        }),
        //columnHelper.accessor(row => calculateTransferState(row.renewalStatus, row.ipRight?.statusOldPaymentProvider), {
        columnHelper.accessor(row => row.ipImport?.transferState, {
            id: 'transfer',
            cell: TransferCell,
            header: HeaderCell,
        }),
        columnHelper.display({
            id: 'edit',
            cell: EditCell,
            //header: EditHeader,
        })
    ]
}

export default function IpImportPage() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {members} = useFilteredPatents()
    const {
        postIpRight, ipRightByMemberId, invalidateIpRights,
        validationsByIpRightId,
        ownershipsByIpRight, postOwnershipLink,
    } = useDennemeyer()
    const { ipRightByDennemeyerId } = useIpRightsPortfolio()
    const {importByIpRightId, postImport} = useImportState()
    const {triggerSynchronize, synchronizing} = useSynchronize()

    const [searchParams] = useSearchParams()
    const filterState = searchParams.get('transferState')
    const filterStates = typeof filterState === 'string' ? filterState.split(',').filter(v => v.trim() !== "") : []
    //console.log({filterStates})

    const [editedRows, setEditedRows] = useState({} as Record<number, IpRightRow>)
    //const [isEditing, setIsEditing] = useState(false)
    //console.log({editedRows})

    const data = members.map(m => {
        const ipRight = ipRightByMemberId[m.familyMemberId]
        const dmIpRight = ipRightByDennemeyerId[ipRight?.dennemeyerId]
        const validations = validationsByIpRightId[ipRight?.ipRightId] ?? []
        const ownerId = ownershipsByIpRight[ipRight?.ipRightId]?.[0]?.ownerId
        const errors = extractMissingValue(m)
        const ipImport = importByIpRightId[ipRight?.ipRightId]
        //console.log({ipRight, dmIpRight, ipRightByDennemeyerId})
        let renewalStatus = extractState(m, ipRight, dmIpRight, validations)
        //console.log({dmIpRight})
        // TODO: calcualte instruction due date
        const nextRenewalDueDate = dmIpRight?.NextMaintenanceActionDueDate
        if (renewalStatus === 'not-sent' && errors.length > 0) {
            renewalStatus = 'not-yet-ready'
        }
        return {...m, ipRight: {...ipRight, ownerId}, validations, renewalStatus, errors, ipImport, nextRenewalDueDate }
    })

    const hasDirtyRights = data.find(d => d?.ipRight?.dirty === true)

    const columns = createColums()
    //console.log({data})

    const meta = {
        editedRows,
        setEditedRows,
        //isEditing,
        //setIsEditing,
        getEditState(rowId: string, path: string, table: Table<IpRightRow>) {
            // TODO: get original values if not edited
            const original = table.getRow(rowId)?.original
            const editedRow = editedRows[rowId]
            //const editedRowValue = editedRow ? _.get(editedRow ?? original, path) : undefined
            const editedRowValue = _.get(editedRow ?? original, path)

            function updateRowValue(value: any) {
                const editedRow = editedRows[rowId] ?? original
                const newRow = { ...editedRow }
                _.set(newRow, path, value)
                setEditedRows(rows => ({ ...rows, [rowId]: newRow }))
            }

            //console.log({original, editedRow, editedRowValue})

            return [editedRow !== undefined, editedRowValue, editedRow ?? original, updateRowValue]
        },
        saveEditedRows: async () => {
            const ipRights = await Promise.all(_(editedRows).values().map(row => postIpRight(row.ipRight)).value())
            invalidateIpRights()
            return ipRights
        },
        saveRow: async (rowId: number) => {
            const row = editedRows[rowId]
            let ipRightId = row?.ipRight?.ipRightId
            const editedIpImport = row?.ipImport
            if (editedIpImport) {
                // ip right should have already been derived
                if (ipRightId === undefined) {
                    const newIpRightId = await postIpRight(row.ipRight)
                    ipRightId = newIpRightId.ipRightId

                    await postOwnershipLink({ ipRightId, ownerId: row.ipRight.ownerId, ownerType: 'registered'})
                }
                await postImport({ ...editedIpImport, ipRightId })
            }
            // Remove from the edited rows
            setEditedRows(rows => ({ ...rows, [rowId]: undefined }))

            return row ? { ...row, ipRight: {...row.ipRight, ipRightId}, ipImport: {...editedIpImport, ipRightId} } : row
        },
    }

    const syncIconStyle = "h-5 w-5 inline mr-1 align-middle"

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('import')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row pb-1">
            <div className="flex flex-row gap-2 justify-between max-w-6xl">
            <h2 className="modern-h2">{t('import')}</h2>
            {isEditUser && <button
                className="btn-secondary disabled:btn-disabled py-px text-sm"
                onClick={() => triggerSynchronize()}
                disabled={hasDirtyRights === undefined}
            >
                {synchronizing ? <IconSpinner className={clsx(syncIconStyle, "animate-spin")} /> : <ArrowPathRoundedSquareIcon className={syncIconStyle} />}
                {synchronizing ? t("renewals-desc.sending") : t("renewals-desc.send")}
            </button>}
            </div>
        </div>
        <div className="main-content bg-pcx-100 pb-20 pt-0 pr-4">
            <ImportFilters {...{filterStates}} />
            <div className="p-4 bg-white rounded-xl shadow xl:min-w-6xl w-fit">
                <IpRightsTable {...{data, filterStates, columns, meta}} />
            </div>
        </div>
    </>
}

function ImportFilters({ filterStates }: { filterStates: string[] }) {
    const { t } = useTranslation()
    
    const filters = [
        {
            label: t('complete'),
            value: 'complete',
            icon: <CheckCircleIcon className="h-5 w-5" />,
            colors: "bg-lime-100 text-lime-600",
        },
        {
            label: t('incomplete'),
            value: 'incomplete',
            icon: <PauseCircleIcon className="h-5 w-5" />,
            colors: "bg-yellow-100 text-yellow-600",
        },
        {
            label: t('unknown'),
            value: 'unknown',
            icon: <QuestionMarkCircleIcon className="h-5 w-5" />,
            colors: "bg-slate-100 text-slate-600",
        },
        {
            label: t('empty'),
            value: 'empty',
            icon: <div className="h-4 w-4 border-2 border-current rounded-full" />,
            colors: "bg-slate-100 text-slate-600",
        },
    ]

    function createSearch(isActive: boolean, filterState: string) {
        if (isActive)
            return `transferState=${filterStates.filter(f => f !== filterState).join(",")}`
        else
            return `transferState=${[...filterStates, filterState].join(",")}`
    }

    return (
        <div className="grid sm:grid-cols-2 md:grid-cols-4 max-w-6xl gap-2 py-2">
            {_(filters).map(({ label, value, icon, colors }) => {
                const isActive = filterStates.includes(value)
                return <Link
                    key={value}
                    to={{ search: createSearch(isActive, value) }}
                    className={clsx(
                        colors, isActive ? 'opacity-80' : 'opacity-30', 'hover:opacity-100',
                        'flex flex-row gap-1 items-center text-xs p-2 rounded shadow border border-current')}
                >
                    {icon} {capitalize(label)}
                </Link>
            }).value()}
        </div>
    )
}

interface IpRightsTableProps {
    data: IpRightRow[];
    filterStates?: string[];
    columns: ColumnDef<IpRightRow, any>[];
    meta: any;
}

function IpRightsTable({ data, filterStates, columns, meta }: IpRightsTableProps) {

    const [sorting, setSorting] = useState<SortingState>([])

    const table = useReactTable({
        columns, 
        data, 
        state: {
            sorting,
            globalFilter: filterStates,
        },
        onSortingChange: setSorting,
        globalFilterFn: (row, id, filterValues) => {
            if (filterValues.length === 0) {
                return true
            } else if (filterValues.find(v => v === 'empty') && row.original?.ipImport?.transferState === undefined) {
                return true
            } else {
                return filterValues.includes(row.original?.ipImport?.transferState)
            }
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        meta,
    })

    return (
        <table>
            <thead>
                {table?.getHeaderGroups().map((headerGroup) =>
                    <tr key={headerGroup.id} className="border-b-2 border-pcx-300">
                        {headerGroup.headers.map(header =>
                            <th key={header.id} className="px-2 first:pl-1 last:pr-1 py-2 whitespace-nowrap text-left">
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                <SortButton {...{header}} />
                            </th>
                        )}
                    </tr>
                )}
            </thead>
            <tbody className="text-sm">
                {table?.getRowModel().rows.map(row =>
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell =>
                            <td key={cell.id} className="px-2 first:pl-1 last:pr-1 py-1">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>)}
                    </tr>)}
            </tbody>
        </table>
    )
}

function SortButton({header}: {header: Header<IpRightRow, unknown>}) {
    if (header.column.getCanSort()) {
        const s = header.column.getIsSorted()
        return (
            <button
                className={clsx(
                    "inline ml-1 align-text-bottom",
                    (s === 'asc' || s === 'desc') ? 'text-pcx-600' : 'text-pcx-400' )} 
                onClick={header.column.getToggleSortingHandler()}>
                {s === 'asc' 
                    ? <IconChevronUp />
                    : s === 'desc'
                    ?  <IconChevronDown /> 
                    : <IconArrowUpDown />
                }
            </button>)
    } else {
        return null
    }
}