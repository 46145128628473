import React, { useState } from "react";
import { BoltIcon, BoltSlashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import { Popover } from "@headlessui/react";
import _ from "lodash";

import ToggleButton from "../components/ToggleButton";
import { saveCrudLinks, useBackend } from "../BackendProvider";
import { IconCircleQuestion, IconCircleX, IconShieldCheck } from "../components/icons";
import { usePrompt } from "../components/blocker";
import { useRoles } from "../user/Auth";
import { PlainImage } from "../components/Image";
import { familyUrl, memberUrl } from "../patents/utils";
import { downloadReport } from "../backend";
import { useMessages } from "../Messages";
import { useLocalState } from "../settings/localStorage";
import clsx from "clsx";
import { Claim } from "../claims/ClaimsProvider";
import { Family, Member } from "../patents/patents";
import { useFilteredPatents } from "../filter/FilteredPatents";
import { useFilteredCommodities } from "../filter/FilteredCommodities";
import { usePatents } from "../patents/PatentsProvider";
import { EmptyPortfolioState } from "../patents/Portfolio";
import { useProducts } from "./ProductsProvider";
import { commodityUrl } from "./products";

type CommodityClaimScopeLink = {commodityId: number; claimScopeId: number}

function isEqualScopeLink(a: CommodityClaimScopeLink, b: CommodityClaimScopeLink) {
    return (a.commodityId === b.commodityId && a.claimScopeId === b.claimScopeId)
}

type CommodityFamilyLink = {commodityId: number; patentFamilyId: number}
function isEqualFamilyLink(a: CommodityFamilyLink, b: CommodityFamilyLink) {
    return (a.commodityId === b.commodityId && a.patentFamilyId === b.patentFamilyId)
}

function getTitle(isThirdParty: boolean, isUndefined: boolean, isProtected: boolean) {
    if (isThirdParty) {
        if (isUndefined) {
            return "undefined-clash"
        } else if (isProtected) {
            return "clash"
        } else {
            return "no-clash"
        }
    } else {
        if (isUndefined) {
            return "undefined-protection"
        } else if (isProtected) {
            return "protected"
        } else {
            return "unprotected"
        }
    }
}

export function ProtectionIcon({isThirdParty, isUndefined = false, isProtected}: {isThirdParty: boolean, isUndefined?: boolean, isProtected: boolean}) {
    if (isUndefined) {
        return <span className="text-pcx-700/40"><IconCircleQuestion className="h-5 w-5" /></span>
    } else if (isThirdParty) {
        if (isProtected) {
            return <span className="text-warn-600"><BoltIcon className="h-5 w-5" /></span>
        } else {
            return <span className="text-pcx-700"><BoltSlashIcon className="h-5 w-5" /></span>
        }
    } else {
        if (isProtected) {
            return <span className="text-pcx-700"><IconShieldCheck className="h-5 w-5" /></span>
        } else {
            return <span className="text-warn-600"><IconCircleX className="h-5 w-5" /></span>
        }
    }
}

// link: { commodityId, claimScopeId}
export type TriStateProps<L> = {
  links: L[], fallBack?: L[], setLinks: (ls: L[]) => void, baseLink: Partial<L>, disabled: boolean, isEqualLink: (l1: Partial<L>, l2: Partial<L>) => boolean, isThirdParty: boolean
}

export function TriState<L>({links, fallBack, setLinks, baseLink, disabled, isEqualLink, isThirdParty} : TriStateProps<L>) {
    const {t} = useTranslation()
    const currentIdx = _.findIndex(links, (l) => isEqualLink(l, baseLink))
    const current = currentIdx < 0 ? fallBack?.find(l => isEqualLink(l, baseLink)) : links[currentIdx]
    //console.log(baseLink, links, currentIdx, current)
    const isUndefined = currentIdx < 0 || current === undefined
    // @ts-ignore
    const isProtected = !isUndefined && current.protected
    function nextState() {
        //console.log(baseLink)
        //console.log(current)
        //console.log(links)
        if (isUndefined) {
            setLinks([...links, {...baseLink, protected: false} as L])
        } else if (isProtected) {
            setLinks([...links.slice(0, currentIdx), ...links.slice(currentIdx + 1)])
        } else {
            setLinks([...links.slice(0, currentIdx), {...baseLink, protected: true} as L, ...links.slice(currentIdx + 1)])
        }
    }
    return (
        <button
            title={t(getTitle(isThirdParty, isUndefined, isProtected))}
            disabled={disabled}
            onClick={() => nextState()}
        >
            <ProtectionIcon {...{isThirdParty, isUndefined, isProtected}} />
        </button>

    )
}


// families: only the active families
function calcClaimScopesByFamily(claims: Claim[], members: Member[], families: Family[]): Record<number, number[]> {
    const claimsByMember = _.groupBy(claims, (c) => c.familyMemberId)
    const claimsByFamily: Record<number, number[]> = (members ?? []).reduce((acc, m) => {
        const cs = claimsByMember[m.familyMemberId]
        if (cs)
            return {...acc, [m.patentFamilyId]: _.uniq([...(acc[m.patentFamilyId] ?? []), ...cs.map(c => c.claimScopeId).filter(c => c)])}
        else
            return acc
    }, {})
    return (families ?? []).reduce((acc, f) => 
        ({...acc, [f.patentFamilyId]: claimsByFamily[f.patentFamilyId] ?? []}),  // return empty list of claims for families without claim scopes
    {})
}

const PatentProductMappings = React.createContext({
    familyLinks: [],
    scopeLinks: [],
    linksByFamilyId: {},
    linksByScopeId: {},
    familyLinksByCommodityId: {},
    scopeLinksByCommodityId: {},
    familyByScopeId: {},
})

function PatentProductMappingsProvider({children, familyLinks, scopeLinks}) {
    const { familyById } = usePatents()
    const {claims} = useBackend()
    const {memberById} = useFilteredPatents()

    //console.log({memberById})

    const familyByScopeId = _(claims)
        .map(({claimScopeId, familyMemberId}) => [claimScopeId, familyById[memberById[familyMemberId]?.patentFamilyId]])
        .filter(([claimScopeId, family]) => claimScopeId !== undefined && family !== undefined)
        .fromPairs()
        .value()

    const linksByFamilyId = _.groupBy(familyLinks, l => l.patentFamilyId)
    const linksByScopeId = _.groupBy(scopeLinks, l => l.claimScopeId)
    const familyLinksByCommodityId = _.groupBy(familyLinks, l => l.commodityId)
    const scopeLinksByCommodityId = _.groupBy(scopeLinks, l => l.commodityId)

    return <PatentProductMappings.Provider value={{
        familyLinks, scopeLinks, 
        linksByFamilyId, linksByScopeId,
        familyLinksByCommodityId, scopeLinksByCommodityId,
        familyByScopeId,
        }}
    >
        {children}
    </PatentProductMappings.Provider>
}

function usePatentProductMappings() {
    return React.useContext(PatentProductMappings)
}


// TODO: check the del function in App.js. Why the flickering?
// TODO: use the useProductMapping hook to get the initial values
// TODO: the working copy is then only the diff of the existing values
export default function ClaimScopesMapping() {
    const {t} = useTranslation()
    const { isEditUser, hasClaimScopes, hasExcelExport } = useRoles()

    const { setErrorMessage } = useMessages()
    const { claimScopes, claims: rawClaims } = useBackend()
    const { 
      commodityClaimScopeLinks, commodityFamilyLinks, 
      postCommodityFamilyLink, deleteCommodityFamilyLink,
      postCommodityClaimScopeLink, deleteCommodityClaimScopeLink,
    } = useProducts()
    const { families: allFamilies } = usePatents()
    const { families, members, memberById } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    //console.log(claims)
    //console.log(commodityFamilyLinks)

    //console.log(originalLinks)
    const [workingScopeLinks, setWorkingScopeLinks] = useState(undefined)
    const [workingFamilyLink, setWorkingFamilyLink] = useState(undefined)
    //console.log(workingFamilyLink)

    const [isEditing, setIsEditing] = useState(false)

    const [showPictures, setShowPictures] = useLocalState('claimscope-mapping-show-pictures', true)
    const [onlyMain, setOnlyMain] = useLocalState('claimscope-mapping-main-claim-only', false)
    const [showFamilyMapping, setShowFamilyMapping] = useLocalState('claimscope-mapping-show-family-mapping', true)

    const claims = rawClaims.filter(c => !onlyMain || c.claimType === "independent-claim")
    // get families, check if family is actually *active*

    const commoditiesByClass = _.groupBy(commodities, c => c.commodityClass)
    const sortedClasses = Object.keys(commoditiesByClass).sort()

    // there should only be at most one family per claim scope
    const claimsByFamily = calcClaimScopesByFamily(claims, members, families) // [familyId] -> [claimScopeId]
    const familyById = _.keyBy(families, f => f.patentFamilyId)
    const sortedFamilies = Object.keys(claimsByFamily).sort((a, b) => familyById[a].internalReference.localeCompare(familyById[b].internalReference))
    const claimScopesById = _.keyBy(claimScopes, c => c.claimScopeId)

    // TODO: reverese: fam -> [claim scopes]
    const membersByClaimScopeId = _(claims)
      .groupBy(c => c.claimScopeId)
      .mapValues(cs => _(cs).map(c => memberById[c.familyMemberId]?.internalReference).filter(Boolean).uniq().sortBy().value())
      .value()

    // check which families also have defined claim scopes, so that non-dev sees dev data (i.e. claim scopes)
    //const combinedFamilyAndClaimScopeLinks 

    async function propagateChanges() {
        if (workingScopeLinks !== undefined) {
            if (hasClaimScopes) {
              await saveCrudLinks(commodityClaimScopeLinks, workingScopeLinks, postCommodityClaimScopeLink, deleteCommodityClaimScopeLink)
              await saveCrudLinks(commodityFamilyLinks, workingFamilyLink, postCommodityFamilyLink, deleteCommodityFamilyLink)
            } else {
                //console.log(commodityFamilyLinks)
                //console.log(workingFamilyLink)
              await saveCrudLinks(commodityFamilyLinks, workingFamilyLink, postCommodityFamilyLink, deleteCommodityFamilyLink)
            }
        }
    }

    function editAction() {
        setWorkingScopeLinks(commodityClaimScopeLinks)
        setWorkingFamilyLink(commodityFamilyLinks)
        setIsEditing(true)
    }
    function saveAction() {
        propagateChanges().then(() => { setWorkingScopeLinks(undefined); setWorkingFamilyLink(undefined) })
        setIsEditing(false)
    }
    function cancelAction() {
        setWorkingScopeLinks(undefined)
        setWorkingFamilyLink(undefined) 
        setIsEditing(false)
    }

    function excelExport() {
        downloadReport({ url: "/api/excel", report: "product-protection-report" }).catch(e => setErrorMessage(e.message))
    }

    usePrompt(t("leave-site-changes"), isEditing)

    const columnCount = sortedFamilies.reduce((acc, family) => {
        const cs = claimsByFamily[family]
        if (cs.length > 0 && hasClaimScopes)
            return acc + cs.length + 1
        else
            return acc + 1
    }, 0)

    if (commodities.length === 0)
      return <>
        {/* @ts-ignore */}
        <Helmet>
          <title>{t('product-patent-mapping')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row">
          <h2 className="modern-h2">{t('product-patent-mapping')}</h2>
        </div>
        <div className="main-content text-center">
          <Trans
            i18nKey="add-products-hint"
            components={{ portfoliolink: <Link className="text-pcx-500 underline-link" to="/products/portfolio" /> }}
          />
        </div>
      </>
    else if (families.length === 0)
      return <>
        {/* @ts-ignore */}
        <Helmet>
          <title>{t('product-patent-mapping')} | Patent Cockpit</title>
        </Helmet>
        <div className="header-row">
          <h2 className="modern-h2">{t('product-patent-mapping')}</h2>
        </div>
        <div className="main-content">
          {allFamilies?.length > 0
            ? <Trans i18nKey="no-patent-results" />
            : <EmptyPortfolioState />
          }
        </div>
      </>
    else
        return (
          <>
            {/* @ts-ignore */}
            <Helmet>
              <title>{t("product-patent-mapping")} | Patent Cockpit</title>
            </Helmet>
            <div className="header-row flex flex-row flex-wrap md:flex-nowrap gap-2">
              <h2 className="modern-h2 whitespace-nowrap">
                {t("product-patent-mapping")}
              </h2>
              <div className="grow" />
              <div className="flex flex-row flex-wrap sm:flex-nowrap sm:gap-4 gap-2 text-sm items-center">
                {hasExcelExport && <button onClick={excelExport} className="btn-secondary py-0.5">
                  {t("excel-export")}
                </button>}
                {isEditUser &&
                  (isEditing ? (
                    <>
                      <button
                        onClick={() => cancelAction()}
                        className="btn-secondary py-0.5"
                      >
                        {t("cancel")}
                      </button>
                      <button
                        onClick={() => saveAction()}
                        className="btn-primary py-0.5"
                      >
                        {t("save")}
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => editAction()}
                      className="btn-secondary py-0.5"
                    >
                      {t("edit")}
                    </button>
                  ))}
                <label className="flex flex-row gap-2 items-center">
                  <span className="whitespace-nowrap">
                    {t("show-pictures")}
                  </span>
                  <ToggleButton
                    {...{ checked: showPictures, setChecked: setShowPictures }}
                  />
                </label>
                {hasClaimScopes && (
                  <label className="flex flex-row gap-2 items-center">
                    <span className="whitespace-nowrap">
                      {t("main-claim-only")}
                    </span>
                    <ToggleButton
                      {...{ checked: onlyMain, setChecked: setOnlyMain }}
                    />
                  </label>
                )}
                {hasClaimScopes && (
                  <label className="flex flex-row gap-2 items-center">
                    <span className="whitespace-nowrap">
                      {t("show-family-mapping")}
                    </span>
                    <ToggleButton
                      {...{
                        checked: showFamilyMapping,
                        setChecked: setShowFamilyMapping,
                      }}
                    />
                  </label>
                )}
              </div>
            </div>

            <PatentProductMappingsProvider
              {...{
                familyLinks: workingFamilyLink ?? commodityFamilyLinks,
                scopeLinks: workingScopeLinks ?? commodityClaimScopeLinks,
              }}
            >
              <div className="max-w-fit min-w-full main-content">
                <table className="relative bg-pc-50 dark:bg-pc-200 border-r-0">
                  <thead>
                    <tr className="">
                      <th className="px-0 bg-pc-50 dark:bg-pc-100 sticky -top-4 -left-4 z-20">
                        <div className="h-1 border border-white dark:border-pc-100 w-full" />{" "}
                        {/* border has to be in here for some werid reason */}
                      </th>
                      {sortedFamilies.flatMap((family) => {
                        const cs = claimsByFamily[family]; // [claimScopeId]
                        const ref = familyById[family].internalReference;
                        const title = familyById[family].familyName;
                        const summary = familyById[family].summary;
                        //console.log(cs, claimScopesById)
                        //console.log(family, cs, ref)
                        //const url = claimScopeImages[claim.claimScopeId]
                        let familyHeader = {
                          ref,
                          summary: familyById[family].summary,
                          claimScopeId: -1,
                          references: [],
                        };
                        let claimScopeHeaders = cs.map((c) => ({
                          ref,
                          summary: claimScopesById[c]?.claimScopeSummary,
                          claimScopeId: c,
                          references: membersByClaimScopeId[c] ?? [],
                        }));
                        const headers =
                          cs.length > 0 && hasClaimScopes
                            ? showFamilyMapping
                              ? [
                                  { ...familyHeader, summary },
                                  ...claimScopeHeaders,
                                ]
                              : claimScopeHeaders
                            : [familyHeader];
                        return headers.map((h) => {
                          return (
                            <th
                              key={`header-${ref}-${h.claimScopeId}}`}
                              className="px-0 font-normal bg-pc-50 dark:bg-pc-100 sticky -top-4 z-10"
                            >
                              <div className="px-px">
                                <Popover className="relative group">
                                  <Popover.Button>
                                    <div className="flex flex-col items-center pb-1">
                                      <div className="flex flex-col min-w-[5rem] w-fit text-left text-sm">
                                        <Link
                                          to={familyUrl({
                                            internalReference: ref,
                                          })}
                                          className="underline-link"
                                        >
                                          {ref}{" "}
                                          {/*<br/> {family} {h.claimScopeId}*/}
                                        </Link>
                                        <div
                                          title={title}
                                          className="max-w-[6rem] overflow-hidden text-ellipsis whitespace-nowrap text-2xs"
                                        >
                                          {title}
                                        </div>
                                      </div>
                                      {showPictures && (
                                        <div className="h-12 w-20">
                                          <PlainImage
                                            {...{
                                              entity: "claim-scope",
                                              entityId: h.claimScopeId,
                                              fallback: {
                                                entity: "patent-family",
                                                entityId: +family,
                                              },
                                              title: ref,
                                              text: h.summary,
                                              clickable: false,
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Popover.Button>
                                  <Popover.Panel
                                    static
                                    className={clsx(
                                      showPictures
                                        ? "top-20 pt-6"
                                        : "top-10 pt-4",
                                      "absolute hidden group-hover:block "
                                    )}
                                  >
                                    <div className="p-4 bg-pc-50 border-2 border-pcx-200 rounded-sm shadow-lg text-left flex flex-col gap-4">
                                      <div className="flex flex-row gap-4">
                                        <div className="flex flex-col gap-1 w-64 ">
                                          <Link
                                            to={familyUrl({
                                              internalReference: ref,
                                            })}
                                            className="underline-link text-lg"
                                          >
                                            {ref}{" "}
                                            {/*<br/> {family} {h.claimScopeId}*/}
                                          </Link>
                                          {title}
                                          <div
                                            className="text-sm text-slate-700"
                                            dangerouslySetInnerHTML={{
                                              __html: h.summary,
                                            }}
                                          />
                                        </div>
                                        <div className="h-32 w-48">
                                          <PlainImage
                                            {...{
                                              entity: "claim-scope",
                                              entityId: h.claimScopeId,
                                              fallback: {
                                                entity: "patent-family",
                                                entityId: +family,
                                              },
                                              title: ref,
                                              text: h.summary,
                                              clickable: false,
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {h.claimScopeId > 0 && <MemberList {...{references: h.references}} />}
                                      <ProductList
                                        {...{
                                          claimScopeId: h.claimScopeId,
                                          patentFamilyId: +family,
                                        }}
                                      />
                                    </div>
                                  </Popover.Panel>
                                </Popover>
                              </div>
                            </th>
                          );
                        });
                      })}
                      <th className="relative bg-pc-50 dark:bg-pc-100">
                        {" "}
                        {/* Making a bit of space on the right side */}
                        <div className="absolute w-20 h-1" />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {sortedClasses.flatMap((cl) => {
                      return [
                        <tr
                          key={cl}
                          className="pt-2 bg-pc-50 dark:bg-pc-100 border-t-2 border-pc-250"
                        >
                          <td className="sticky -left-2 sm:-left-4 ">
                            <div className="px-2 w-32 whitespace-nowrap">
                              <h3 className="text-pcx-500/80 font-medium text-sm">
                                {cl !== "undefined" ? cl : t("unclassified")}
                              </h3>
                            </div>
                          </td>
                          {_.range(columnCount).map((i) => (
                            <td key={`${cl}-${i}`}></td>
                          ))}
                          <td className="bg-pc-50 dark:bg-pc-100" />
                        </tr>,
                        ...commoditiesByClass[cl]
                          .sort((a, b) =>
                            a.commodityReference.localeCompare(
                              b.commodityReference
                            )
                          )
                          .map((commodity, cIdx) => {
                            return (
                              <tr
                                key={`product-${commodity.commodityReference}-${cIdx}`}
                                className="bg-pc-50 dark:bg-pc-100 border-t-2 border-pc-200"
                              >
                                <td className="leading-5 sticky z-10 group hover:z-20 -left-2 sm:-left-4 ">
                                  <Popover className="relative">
                                    <Popover.Button className="bg-pc-50 dark:bg-pc-100 pb-1 pl-4 text-left">
                                      <Link
                                        className="w-32 block overflow-hidden text-ellipsis"
                                        to={commodityUrl(commodity)}
                                      >
                                        <span className="whitespace-nowrap underline-link text-sm">
                                          {" "}
                                          {commodity.commodityReference}
                                        </span>
                                        {showPictures && (
                                          <div className="w-28 h-12">
                                            <PlainImage
                                              {...{
                                                entity: "commodity",
                                                entityId: commodity.commodityId,
                                                clickable: false,
                                              }}
                                            />
                                          </div>
                                        )}
                                      </Link>
                                    </Popover.Button>
                                    <Popover.Panel
                                      static
                                      className="absolute hidden group-hover:block -top-11 left-0 pl-0"
                                    >
                                      <div
                                        className={clsx(
                                          "p-1 pb-0 ",
                                          showPictures ? "pt-4" : "pt-3.5",
                                          "bg-pc-50/90 border-2 border-pcx-200 rounded-sm shadow-lg"
                                        )}
                                      >
                                        <div
                                          className={clsx(
                                            "text-pcx-500 p-0.5 font-medium text-sm",
                                            showPictures ? "" : "mb-0.5"
                                          )}
                                        >
                                          {cl !== "undefined"
                                            ? cl
                                            : t("unclassified")}
                                        </div>
                                        <Link
                                          className="block pl-2.5 pt-0.5 underline-link whitespace-nowrap text-sm"
                                          to={commodityUrl(commodity)}
                                        >
                                          {commodity.commodityReference}
                                        </Link>
                                        <div className="flex flex-row gap-4 mt-4 px-3.5 pb-4 bg-pc-50 w-full">
                                          <div className="w-48 h-32">
                                            <PlainImage
                                              {...{
                                                entity: "commodity",
                                                entityId: commodity.commodityId,
                                                clickable: false,
                                              }}
                                            />
                                          </div>
                                          <div className="w-64 empty:hidden text-sm text-slate-700">
                                            {commodity.commodityDescription}
                                          </div>
                                          <PatentList
                                            {...{
                                              familyLinks:
                                                workingFamilyLink ??
                                                commodityFamilyLinks,
                                              claimScopeLinks:
                                                workingScopeLinks ??
                                                commodityClaimScopeLinks,
                                              commodityId:
                                                commodity.commodityId,
                                              isThirdParty:
                                                commodity.isThirdParty ?? false,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Popover>
                                </td>

                                {sortedFamilies.flatMap((family) => {
                                  const cs = claimsByFamily[family]; // [claimScopeId]
                                  const commodityId = commodity.commodityId;

                                  function claimScopeCells() {
                                    return cs.map((c) => {
                                      const key = `${c}-${commodity.commodityId}`;
                                      return (
                                        <td
                                          key={key}
                                          className="text-center pt-1 pb-0"
                                        >
                                          <TriState
                                            {...{
                                              baseLink: {
                                                commodityId,
                                                claimScopeId: c,
                                              },
                                              links:
                                                workingScopeLinks ??
                                                commodityClaimScopeLinks,
                                              setLinks: setWorkingScopeLinks,
                                              disabled: !isEditing,
                                              isEqualLink: isEqualScopeLink,
                                              isThirdParty:
                                                commodity.isThirdParty ?? false,
                                            }}
                                          />
                                        </td>
                                      );
                                    });
                                  }

                                  function familyScopeCell() {
                                    const linksByClaimScopes = _(
                                      workingScopeLinks ??
                                        commodityClaimScopeLinks
                                    )
                                      .filter(
                                        (l) =>
                                          cs.find(
                                            (c) => c === l.claimScopeId
                                          ) && l.protected !== undefined
                                      )
                                      .map((l) => ({
                                        ...l,
                                        patentFamilyId: +family,
                                      }))
                                      .value();

                                    // if any is true, whole family is true
                                    const condensed =
                                      linksByClaimScopes.length <= 1
                                        ? linksByClaimScopes
                                        : [
                                            {
                                              ...linksByClaimScopes[0],
                                              protected:
                                                linksByClaimScopes.reduceRight(
                                                  (acc, elem) =>
                                                    acc || elem.protected,
                                                  false
                                                ),
                                            },
                                          ];
                                    //console.log(linksByClaimScopes)
                                    //console.log(workingFamilyLink)
                                    return (
                                      <td
                                        key={`fam-${family}-${commodity.commodityId}`}
                                        id={`fam-${family}-${commodity.commodityId}`}
                                        className="text-center pt-1 pb-0"
                                      >
                                        <TriState
                                          {...{
                                            baseLink: {
                                              commodityId,
                                              patentFamilyId: +family,
                                            },
                                            links:
                                              workingFamilyLink ??
                                              commodityFamilyLinks,
                                            fallBack: condensed,
                                            setLinks: setWorkingFamilyLink,
                                            disabled: !isEditing,
                                            isEqualLink: isEqualFamilyLink,
                                            isThirdParty:
                                              commodity.isThirdParty ?? false,
                                          }}
                                        />
                                      </td>
                                    );
                                  }

                                  if (cs.length > 0 && hasClaimScopes)
                                    return showFamilyMapping
                                      ? [
                                          familyScopeCell(),
                                          ...claimScopeCells(),
                                        ]
                                      : claimScopeCells();
                                  else {
                                    return familyScopeCell();
                                  }
                                })}
                                <td className="bg-pc-50 dark:bg-pc-100" />
                              </tr>
                            );
                          }),
                      ];
                    })}
                  </tbody>
                </table>

                <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 mt-8 sticky sm:-left-4 pl-2">
                  {[
                    {
                      isUndefined: true,
                      isProtected: true,
                      isThirdParty: false,
                    },
                    {
                      isUndefined: false,
                      isProtected: true,
                      isThirdParty: false,
                    },
                    {
                      isUndefined: false,
                      isProtected: false,
                      isThirdParty: false,
                    },
                    {
                      isUndefined: false,
                      isProtected: true,
                      isThirdParty: true,
                    },
                    {
                      isUndefined: false,
                      isProtected: false,
                      isThirdParty: true,
                    },
                  ].map((state, i) => (
                    <div key={i} className="inline-flex items-center gap-1 text-sm text-slate-500">
                      <ProtectionIcon {...state} />
                      {t(
                        getTitle(
                          state.isThirdParty,
                          state.isUndefined,
                          state.isProtected
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </PatentProductMappingsProvider>
          </>
        );
}

function MemberList({references}: {references: string[]}) {
  const {t} = useTranslation()

  return references.length === 0 
    ? null 
    : <div className="space-y-1">
      <h5 className="text-pcx-500 font-base font-normal">{t('patents')}</h5>
      {references.map(internalReference =>
        <Link key={internalReference} className="hover:underline-link text-sm text-pcx-700 after:content-[';_'] last:after:content-['']" to={memberUrl({ internalReference })}>
          {internalReference}
        </Link>)}
    </div>
}

function ProductList({patentFamilyId, claimScopeId}) {
    const {t} = useTranslation()
    const {commodityById} = useProducts()
    const {linksByFamilyId, linksByScopeId} =  usePatentProductMappings()
    const products = (linksByScopeId[claimScopeId] ?? linksByFamilyId[patentFamilyId] ?? [])
        .filter(l => l.protected)
        .map(l => ({...commodityById[l.commodityId], ...l}))

    if (products.length === 0)
        return <div>
            <h5 className="font-base text-slate-500 font-normal">{t('no-affected-products')}</h5>
        </div>
    else
        return (
            <div>
                <h5 className="font-base text-pcx-500 font-normal">{t('affected-products')}</h5>
                {_(products)
                    .map(p => ({ ...p, name: `${p.commodityClass}: ${p.commodityReference}` }))
                    .sortBy('name')
                    .map(p =>
                        <div key={p.commodityId} className="flex flex-row gap-1 text-sm mt-1">
                            <ProtectionIcon isProtected={true} isThirdParty={p.isThirdParty} /> <Link to={commodityUrl(p)}>{p.name}</Link>
                        </div>)
                    .value()
                }
            </div>
        )
}

function PatentList({commodityId, isThirdParty}) {
    const {t} = useTranslation()
    const {familyLinksByCommodityId, scopeLinksByCommodityId, familyByScopeId} = usePatentProductMappings()
    const {familyById} = usePatents()
    const patentFamilies = (familyLinksByCommodityId[commodityId] ?? [])
        .filter(l => l.protected)
        .map(l => familyById[l.patentFamilyId])
    const claimScopesFamilies = (scopeLinksByCommodityId[commodityId] ?? [])
        .filter(l => l.protected)
        .map(l => familyByScopeId[l.claimScopeId])
    const patents = _([...patentFamilies, ...claimScopesFamilies]).filter(Boolean).uniqBy('patentFamilyId').sortBy('internalReference').value()

    return <div className="" > {
        (patents.length === 0) ?
            <h5 className="whitespace-nowrap font-base text-slate-500 font-normal">{t('no-overlapping-patents')}</h5>
            : <>
                <h5 className="whitespace-nowrap font-base text-pcx-500 font-normal">{t('overlapping-patents')}</h5>
                {patents.map(p => // TODO sortieren? Symbol
                    <div key={'patents-' + p.internalReference} className="flex flex-row gap-1 text-sm mt-1">
                        <ProtectionIcon isProtected={true} isThirdParty={isThirdParty} /> <Link className="hover:underline-link tabular-nums" to={familyUrl(p)}>{p.internalReference}</Link>
                    </div>)}
            </>
    }
    </div>
}